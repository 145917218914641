import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
// import Hero from '@components/Hero/Hero';
import HeroWinter from '@components/HeroWinter/HeroWinter';
// import EBikes from '@components/EBikes/EBikes';
import WinterGoods from '@components/WinterGoods/WinterGoods';

const IndexPage = ({ location }) => {
  return (
    <Layout location={location} map en>
      <Seo
        title="E-Bike & Rental Champoluc | Tiziano Bieller Sport"
        description="Your ski rental in Champoluc - Valle d'Aosta. Ski equipment, crampons, bobsleds and snowshoes with various rental options. E-Bike rental during the summer season."
        // description="Your E-Bike rental in Champoluc - Valle d'Aosta. Pedal-assisted bicycles for adults and children with various rental options. Ski rental during the winter season."
        lang="en"
      />
      <HeroWinter en />
      <WinterGoods en />
    </Layout>
  );
};

export default IndexPage;
